body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
 
  .layout__Content__1x5Dd{
      margin-top:72px;
  }
.Toolbar__Toolbar__2kJwM{
    height: 56px;
    width: 100%;
    position:fixed;
    top: 0;
    left: 0;
    background-color: #703809;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 20px;
    box-sizing: border-box;
    z-index: 90;
}

.Toolbar__Toolbar__2kJwM nav{
    height: 100%;

}

.Toolbar__Logo__113zl{
    height:80%;
}

@media (max-width:499px){
    .Toolbar__DesktopOnly__r1CMo{
        display:none;
    }
}
.Logo__Logo__19WaN{
    background-color: #fff;
     padding:8px;
     height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
}
.Logo__Logo__19WaN img{
    height:100%;
}
.NavigationItems__NavigationItems__1udtt{
    margin: 0;
    padding: 0;
    list-style: none;
    display:flex;
    flex-flow: column;
    align-items: center;
    height:100%;
}

@media (min-width:500px){
    .NavigationItems__NavigationItems__1udtt{
        flex-flow:row;
    }
}

.NavigationItem__NavigationItem__3X5de{
    margin: 10px 0;
    box-sizing: border-box;
    display:block;
    width:100%;

}

.NavigationItem__NavigationItem__3X5de a{
    color: #8F5C2C ;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display:block;
}

.NavigationItem__NavigationItem__3X5de a:hover, 
.NavigationItem__NavigationItem__3X5de a:active,
.NavigationItem__NavigationItem__3X5de a.NavigationItem__active__SlH7U{

    color: #40A4C8;
}

@media (min-width:500px){
    .NavigationItem__NavigationItem__3X5de{
        margin: 0;
        display:flex;
        height:100%;
        width:auto;
        align-items: center;
    }
    
    .NavigationItem__NavigationItem__3X5de a{
        color: white;
        height: 100%;
        padding: 16px 10px;
        border-bottom: 1px solid transparent;
    }
    
    .NavigationItem__NavigationItem__3X5de a:hover, 
    .NavigationItem__NavigationItem__3X5de a:active,
    .NavigationItem__NavigationItem__3X5de a.NavigationItem__active__SlH7U{
        background-color: #8F5C2C;
        border-bottom: 4px solid #40A4C8;
        color: white;
    }   
} 
.DrawerToggle__DrawerToggle__3h9Nk {
    width: 40px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.DrawerToggle__DrawerToggle__3h9Nk div {
    width: 90%;
    height: 3px;
    background-color: white;
}

@media (min-width: 500px) {
    .DrawerToggle__DrawerToggle__3h9Nk {
        display: none;
    }
}
.SideDrawer__SideDrawer__3__Ja{
    position: fixed;
    width: 280px;
    min-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: #fff;
    padding: 32px 16px;
    box-sizing: border-box;
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;

}

@media(min-width:500px){
    .SideDrawer__SideDrawer__3__Ja{
        display:none;
    }
}
.SideDrawer__Open__2Pp4a{
    -webkit-transform:translateX(0);
            transform:translateX(0);
}

.SideDrawer__Close__3GAFT{
    -webkit-transform:translateX(-100%);
            transform:translateX(-100%);
}

.SideDrawer__Logo__9Tynh{
    height:11%;
    margin-bottom:20px;
}
.Backdrop__Backdrop__efy1y {
    width:100%;
    height:100%;
    position:fixed;
    z-index: 100;
    left:0;
    top:0;
    background-color: rgb(0,0,0,0.5) ;
}
.BurgerIngredient__BreadBottom__pgUas {
    height: 13%;
    width: 80%;
    background: -webkit-linear-gradient(#F08E4A, #e27b36);
    background: linear-gradient(#F08E4A, #e27b36);
    border-radius: 0 0 30px 30px;
    box-shadow: inset -15px 0 #c15711;
    margin: 2% auto;
}

.BurgerIngredient__BreadTop__1qwk- {
    height: 20%;
    width: 80%;
    background: -webkit-linear-gradient(#bc581e, #e27b36);
    background: linear-gradient(#bc581e, #e27b36);
    border-radius: 50% 50% 0 0;
    box-shadow: inset -15px 0 #c15711;
    margin: 2% auto;
    position: relative;
}

.BurgerIngredient__Seeds1__1NoR3 {
    width: 10%;
    height: 15%;
    position: absolute;
    background-color: white;
    left: 30%;
    top: 50%;
    border-radius: 40%;
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
    box-shadow: inset -2px -3px #c9c9c9;
}

.BurgerIngredient__Seeds1__1NoR3:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: white;
    left: -170%;
    top: -260%;
    border-radius: 40%;
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
    box-shadow: inset -1px 2px #c9c9c9;
  }
  
.BurgerIngredient__Seeds1__1NoR3:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: white;
    left: 180%;
    top: -50%;
    border-radius: 40%;
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
    box-shadow: inset -1px -3px #c9c9c9;
  }

  .BurgerIngredient__Seeds2__rJjjN {
    width: 10%;
    height: 15%;
    position: absolute;
    background-color: white;
    left: 64%;
    top: 50%;
    border-radius: 40%;
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
    box-shadow: inset -3px 0 #c9c9c9;
  }
  
  .BurgerIngredient__Seeds2__rJjjN:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: white;
    left: 150%;
    top: -130%;
    border-radius: 40%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    box-shadow: inset 1px 3px #c9c9c9;
  }
  

.BurgerIngredient__Meat__27By3 {
    width: 80%;
    height: 8%;
    background: -webkit-linear-gradient(#7f3608, #702e05);
    background: linear-gradient(#7f3608, #702e05);
    margin: 2% auto;
    border-radius: 15px;
}

.BurgerIngredient__Cheese__3Xxg- {
    width: 90%;
    height: 4.5%;
    margin: 2% auto;
    background: -webkit-linear-gradient(#f4d004, #d6bb22);
    background: linear-gradient(#f4d004, #d6bb22);
    border-radius: 20px;
}

.BurgerIngredient__Salad__PEJF9 {
    width: 85%;
    height: 7%;
    margin: 2% auto;
    background: -webkit-linear-gradient(#228c1d, #91ce50);
    background: linear-gradient(#228c1d, #91ce50);
    border-radius: 20px;
}

.BurgerIngredient__Bacon__1FuPv {
    width: 80%;
    height: 3%;
    background: -webkit-linear-gradient(#bf3813, #c45e38);
    background: linear-gradient(#bf3813, #c45e38);
    margin: 2% auto;
}
.burger__Burger__QkVd9{
    width:100%;
    margin: auto;
    height: 250px;
    overflow:scroll;
    text-align:center;
    font-weight:bold;
    font-size:1.2rem;

}

@media (min-width: 500px) and (min-height:400px){
    .burger__Burger__QkVd9{
        width:350px;
        height:300px;
    }
}

@media (min-width: 1000px) and (min-height:700px){
    .burger__Burger__QkVd9{
        width:700px;
        height:600px;
    }
}

@media (min-width: 500px) and (min-height:401px){
    .burger__Burger__QkVd9{
        width:450px;
        height:400px;
    }
}

@media (min-width: 1000px) and (min-height:700px){
    .burger__Burger__QkVd9{
        width:700px;
        height:600px;
    }
}
.BuildControls__BuildControls__3_01f {
    width: 100%;
    background-color: #CF8F2E;
    display: flex;
    flex-flow: column;
    align-items: center;
    box-shadow: 0 2px 1px #ccc;
    margin: auto;
    padding: 10px 0;
    float: left;
}

.BuildControls__OrderButton__myBwT {
    background-color: #DAD735;
    outline: none;
    cursor: pointer;
    border: 1px solid #966909;
    color: #966909;
    font-family: inherit;
    font-size: 1em;
    padding: 10px 30px;
    box-shadow: 2px 2px 2px #966909;
}

.BuildControls__OrderButton__myBwT:hover, .BuildControls__OrderButton__myBwT:active {
    background-color: #A0DB41;
    border: 1px solid #966909;
    color: #966909;
}

.BuildControls__OrderButton__myBwT:disabled {
    background-color: #C7C6C6;
    cursor: not-allowed;
    border: 1px solid #ccc;
    color: #888888;
}

.BuildControls__OrderButton__myBwT:not(:disabled) {
    -webkit-animation: BuildControls__enable__3jYIq 0.3s linear;
            animation: BuildControls__enable__3jYIq 0.3s linear;
}

@-webkit-keyframes BuildControls__enable__3jYIq {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    60% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes BuildControls__enable__3jYIq {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    60% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
.BuildControl__BuildControl__1jYc3{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:3px 0;

}
.BuildControl__BuildControl__1jYc3 button{
    display: block;
    font: inherit;
    padding: 3px;
    margin: 0 5px;
    width:80px;
    border:1px solid #AA6817;
    cursor:pointer;
    outline:none;
}

.BuildControl__BuildControl__1jYc3 button:disabled {
    background-color: #AC9980;
    border: 1px solid #7E7365;
    color: #ccc;
    cursor: default;
}

.BuildControl__BuildControl__1jYc3 button:hover:disabled {
    background-color: #AC9980;
    color: #ccc;
    cursor: not-allowed;
}

.BuildControl__Label__33Z-p {
    padding: 10px;
    font-weight: bold;
    width: 80px;
}

.BuildControl__BuildControl__1jYc3 .BuildControl__Less__377MJ {  
    background-color: #D39952;
    color: white;
}

.BuildControl__BuildControl__1jYc3 .BuildControl__More__28-hQ {
    background-color: #8F5E1E;
    color: white;
}

.BuildControl__BuildControl__1jYc3 .BuildControl__Less__377MJ:hover, .BuildControl__BuildControl__1jYc3 .BuildControl__Less__377MJ:active {  
    background-color: #DAA972;
    color: white;
}

.BuildControl__BuildControl__1jYc3 .BuildControl__More__28-hQ:hover,.BuildControl__BuildControl__1jYc3 .BuildControl__More__28-hQ:active {
    background-color: #99703F;
    color: white;
}
.Modal__Modal__32_-a {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 30%;
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
    .Modal__Modal__32_-a {
        width: 500px;
        left: calc(50% - 250px);
    }
}
.Button__Button__QI7b2 {
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    font-weight: bold;
}

.Button__Button__QI7b2:first-of-type {
    margin-left: 0;
    padding-left: 0;
}

.Button__Success__2dHUt {
    color: #5C9210;
}

.Button__Danger__2xnhN {
    color: #944317;
}
.Spinner__Loader__1DDwY,
.Spinner__Loader__1DDwY:before,
.Spinner__Loader__1DDwY:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: Spinner__load7__2S5ij 1.8s infinite ease-in-out;
  animation: Spinner__load7__2S5ij 1.8s infinite ease-in-out;
}
.Spinner__Loader__1DDwY {
  color: #061257;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.Spinner__Loader__1DDwY:before,
.Spinner__Loader__1DDwY:after {
  content: '';
  position: absolute;
  top: 0;
  background: #fff;
}
.Spinner__Loader__1DDwY:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.Spinner__Loader__1DDwY:after {
  left: 3.5em;
}
@-webkit-keyframes Spinner__load7__2S5ij {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes Spinner__load7__2S5ij {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.CheckoutSummary__CheckoutSummary__3SGBL{
    text-align: center;
    width:80%;
    margin: auto;

}

@media (min-width:600px){
    .CheckoutSummary__CheckoutSummary__3SGBL{

        width:500px;

    
    }
}
.ContactData__ContactData__1J81r{
    margin:20px auto;
    width:80%;
    text-align:center;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding:10px;
    box-sizing: border-box;

}

@media (min-width:600px){
    .ContactData__ContactData__1J81r{
        width:500px;
    }
}
.Input__Input__s67N0 {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.Input__Label___n-1m {
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
}

.Input__InputElement__2-aFx {
  outline: none;
  border: 1px solid #ccc;
  background-color: white;
  font: inherit;
  padding: 6px 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.Input__InputElement__2-aFx:focus {
  outline: none;
  background-color: #ccc;
}

.Input__Invalid__1sl1p {
  border: 1px solid red;
  background-color: #fda49a;
}

.Order__Order__2bw7A{
    width:80%;
    border:1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    padding:10px;
    margin:10px auto;
    box-sizing: border-box;
}
